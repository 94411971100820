import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";

import { GetAllPaymentProfiles, GetMerchantPaymentContext, GetPaymentContext } from "@/api/payments";
import type { DtoPaymentProfile, MerchantsMerchantType, PaymentMerchantConfig } from "@/services/api";
import { PaymentPaymentContextRequestContextTypeEnum } from "@/services/api";
import { useAuthStore } from "@/store/auth.store";
import { useCartStore } from "@/store/cart.store";
import { useAppStateStore } from "@/store/state.store";

interface GetPaymentConfigParams {
  contextType?: PaymentPaymentContextRequestContextTypeEnum;
  merchant?: MerchantsMerchantType;
}

export interface AppState {
  paymentConfig: PaymentMerchantConfig;
  paymentProfiles: DtoPaymentProfile[];
  selectedPaymentProfile: DtoPaymentProfile | null;
  forceHideFailedSubscriptionAlert: boolean;
  boost: boolean;
}

export const usePaymentsStore = defineStore("payments", {
  state: (): AppState => ({
    paymentConfig: {},
    paymentProfiles: [],
    selectedPaymentProfile: null,
    forceHideFailedSubscriptionAlert: false,
    boost: false
  }),
  getters: {
    configuredMerchant(): string | undefined {
      return this.paymentConfig.merchant_code;
    },
    defaultPaymentProfile(state): DtoPaymentProfile | undefined {
      return (
        state.selectedPaymentProfile ??
        state.paymentProfiles.sort(
          (a, b) =>
            Number(new Date(b.created_at ? b.created_at.toString() : 0)) -
            Number(new Date(a.created_at ? a.created_at.toString() : 0))
        )[0]
      );
    },
    getMerchantFromPaymentConfig(state): string {
      const force_merchant = sessionStorage.getItem("force-merchant") as MerchantsMerchantType | null;

      if (force_merchant) return force_merchant;

      return state.paymentConfig.merchant_code ? state.paymentConfig.merchant_code.toLowerCase() : "";
    },
    currentPaymentProfileId(): string | undefined {
      const defaultPaymentProfile: DtoPaymentProfile | undefined = this.defaultPaymentProfile;

      return defaultPaymentProfile?.id;
    },
    userHasPaymentProfile(): boolean {
      return !!this.currentPaymentProfileId;
    }
  },
  actions: {
    setPaymentConfig(config: PaymentMerchantConfig | undefined) {
      this.paymentConfig = config ?? {};
    },
    async getPaymentConfig(contextType?: PaymentPaymentContextRequestContextTypeEnum) {
      const { deviceId } = useAuthStore();
      const { cart } = useCartStore();
      const hostname = window.location.host;
      let config: PaymentMerchantConfig | undefined = undefined;
      let attempts = 0;
      const maxAttempts = 3;
      const delays = [2000, 5000, 10000];
      const appStateStore = useAppStateStore();

      while (attempts < maxAttempts) {
        attempts++;
        config = await GetPaymentContext(
          hostname,
          contextType ?? PaymentPaymentContextRequestContextTypeEnum.PaymentIntent,
          deviceId,
          cart
        );

        if (config?.merchant_code) {
          break;
        } else {
          Sentry.captureMessage(
            `Attempt ${attempts}/${maxAttempts}: No merchant_code in merchant context, config: ${JSON.stringify(config)}`
          );

          await appStateStore.getSettings();

          if (attempts < maxAttempts) {
            await new Promise((resolve) => setTimeout(resolve, delays[attempts - 1]));
          }
        }
      }

      this.setPaymentConfig(config);
    },
    async getMerchantPaymentConfig({
      merchant,
      contextType = PaymentPaymentContextRequestContextTypeEnum.Basic
    }: GetPaymentConfigParams) {
      const { deviceId } = useAuthStore();
      const { cart } = useCartStore();

      if (!merchant) return;

      const hostname = window.location.host;
      const config = await GetMerchantPaymentContext(merchant, hostname, contextType, deviceId, cart);

      this.setPaymentConfig(config);
    },
    setPaymentProfiles(profiles: DtoPaymentProfile[]) {
      this.paymentProfiles = profiles;
    },
    appendPaymentProfile(profile: DtoPaymentProfile) {
      const profiles = this.paymentProfiles ?? [];
      const profileIndex = profiles.findIndex((p) => p.id === profile.id);

      if (profileIndex !== -1) {
        profiles[profileIndex] = profile;
      } else {
        this.paymentProfiles = [...profiles, profile];
      }
    },
    setMerchant(code: string) {
      this.paymentConfig.merchant_code = code;
    },
    async getPaymentProfiles() {
      const profiles = await GetAllPaymentProfiles();

      this.setPaymentProfiles(profiles);
    },
    setForceHideFailedSubscriptionAlert(state: boolean) {
      this.forceHideFailedSubscriptionAlert = state;
    },
    setBoost(value: boolean) {
      this.boost = value;
    },
    setSelectedPaymentProfile(value: DtoPaymentProfile | null) {
      this.selectedPaymentProfile = value;
    }
  }
});
