import { PaymentsAPI, PurchasesAPI, SubscriptionsAPI } from "@/api";
import type {
  DtoCart,
  DtoClientSideTokenizationError,
  DtoEditSubscriptionRequest,
  DtoPaymentProfile,
  DtoPaymentResult,
  DtoProductInfo,
  DtoProfilePaymentRequest,
  DtoShieldSubscriptionRequest,
  DtoSubscriptionDataRecord,
  MerchantsMerchantType,
  PaymentPaymentContextRequestContextTypeEnum,
  PaymentPaymentProfileRequest,
  PurchasesUserPurchaseResponse
} from "@/services/api";
import { getLogger } from "@/util/logger";
import { createPaymentContextQueryParams } from "@/util/query-string";

const log = getLogger("API: Payments");

export const ChargeProfile = async (profileId: string, params: DtoProfilePaymentRequest): Promise<DtoPaymentResult> => {
  log.time("Payment via Profile call");

  const { data } = await PaymentsAPI.charge(profileId, params);

  log.timeEnd("Payment via Profile call");

  return data;
};

export const NewPaymentProfile = async (request: PaymentPaymentProfileRequest): Promise<DtoPaymentProfile> => {
  log.time("Creating New Payment Profile");

  const params = createPaymentContextQueryParams();

  const { data } = await PaymentsAPI.newPaymentProfile(request, { params });

  log.timeEnd("Creating New Payment Profile");

  return data;
};

export const TokenizationFailure = async (
  merchant: string,
  merchantErrorRegistrationRequest: DtoClientSideTokenizationError
): Promise<DtoClientSideTokenizationError> => {
  log.time("Sending tokenization error");

  const { data } = await PaymentsAPI.tokenizationFailure(merchant, merchantErrorRegistrationRequest);

  log.timeEnd("Sending tokenization error");

  return data;
};
export const SetDefaultProfile = async (profileId: string): Promise<DtoPaymentProfile> => {
  log.time("SetDefaultProfile call");

  const { data } = await PaymentsAPI.updatePaymentProfile(profileId);

  log.timeEnd("SetDefaultProfile call");

  return data;
};

export const SubscribeShield = async (params: DtoShieldSubscriptionRequest) => {
  log.time("Subscribing to the shield");

  await SubscriptionsAPI.addShieldSubscription(params);

  log.timeEnd("Subscribing to the shield");
};

export const GetAllPaymentProfiles = async (): Promise<DtoPaymentProfile[]> => {
  log.time("Get payment profile list");

  const { data } = await PaymentsAPI.getPaymentProfiles();

  log.timeEnd("Get payment profile list");

  return data;
};

export const GetPaymentContext = async (
  hostname: string,
  contextType: PaymentPaymentContextRequestContextTypeEnum,
  deviceId: string,
  cart?: DtoCart
) => {
  try {
    const params = createPaymentContextQueryParams();

    const { data } = await PaymentsAPI.getPaymentContext(
      { hostname, device_id: deviceId, context_type: contextType, cart },
      {
        params
      }
    );

    return data;
  } catch {
    log.error("GetPaymentContext Error");
  }
};

export const GetMerchantPaymentContext = async (
  merchant: MerchantsMerchantType,
  hostname: string,
  contextType: PaymentPaymentContextRequestContextTypeEnum,
  deviceId: string,
  cart?: DtoCart
) => {
  try {
    const { data } = await PaymentsAPI.getPaymentContext(
      { hostname, device_id: deviceId, context_type: contextType, cart },
      {
        params: {
          force_merchant: merchant
        }
      }
    );

    return data;
  } catch {
    log.error("GetPaymentContext Error");
  }
};

export const HasExpiredSubscriptions = async () => {
  log.time("Check if user has expired subscriptions");

  const { data } = await SubscriptionsAPI.hasExpiredSubscriptions();

  log.timeEnd("Checked if user has expired subscriptions");

  return data;
};

export const EditShieldSubscription = async (params: DtoEditSubscriptionRequest) => {
  log.time("List payment profile list");

  const { data } = await SubscriptionsAPI.editShieldSubscription(params);

  log.timeEnd("List payment profile list");

  return data;
};

export const EditBundleSubscription = async (params: DtoEditSubscriptionRequest) => {
  log.time("List payment profile list");

  const { data } = await SubscriptionsAPI.editBundleSubscription(params);

  log.timeEnd("List payment profile list");

  return data;
};

export const GetShieldStatus = async (formUid: string): Promise<DtoSubscriptionDataRecord> => {
  log.time("Get shield status call");

  const { data } = await SubscriptionsAPI.getShieldStatus(formUid);

  log.timeEnd("Get shield status call");

  return data;
};

export const GetBundleStatus = async (formUid: string): Promise<DtoSubscriptionDataRecord> => {
  log.time("Get bundle status call");

  const { data } = await SubscriptionsAPI.getBundleStatus(formUid);

  log.timeEnd("Get bundle status call");

  return data;
};

export const GetSubscriptionStatuses = async (): Promise<DtoSubscriptionDataRecord[]> => {
  log.time("Get subscription statuses call");

  const { data } = await SubscriptionsAPI.getSubscriptionStatuses();

  log.timeEnd("Get subscription statuses call");

  return data;
};

export const GetAllActiveUserPurchases = async (): Promise<PurchasesUserPurchaseResponse> => {
  log.time("Get all active purchases status call");

  const { data } = await PurchasesAPI.getUserPurchases();

  log.timeEnd("Get all active purchases status call");

  return data;
};

export const GetPurchaseProductInfo = async (sku: string): Promise<DtoProductInfo> => {
  log.time("Get purchase product info");

  const { data } = await PurchasesAPI.productBySku(sku);

  log.timeEnd("Get purchase product info");

  return data;
};
