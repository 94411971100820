import type { RouteRecordRaw } from "vue-router";

import { BRAND } from "@/config/constants";
import { FLOW, ROUTE_NAMES } from "@/util/routes/route-names";

export const TAX_SPECIFIC_ROUTES: RouteRecordRaw[] = [
  {
    path: "/contact-us",
    name: ROUTE_NAMES.contactUs,
    component: () => import("@/views/contact-us.vue"),
    meta: {
      title: `${BRAND} - Contact Us`
    }
  },
  {
    path: "/tax-return-preparation/done-for-you",
    name: ROUTE_NAMES.doneForYou,
    component: () => import("@/views/dfy/landing.vue"),
    meta: {
      title: `${BRAND} - Done for you`,
      layout: "full",
      container: "big",
      flow: FLOW.dfyReturn
    }
  },
  {
    path: "/tax-return-preparation/5-common-mistakes",
    name: ROUTE_NAMES.commonMistakes,
    component: () => import("@/views/common-mistakes.vue"),
    meta: {
      title: `${BRAND} - Don't make these common mistakes when filing your Tax Return`,
      flow: FLOW.diyReturn,
      layout: "full"
    }
  },
  {
    path: "/faq",
    name: ROUTE_NAMES.faq,
    component: () => import("@/views/faq.vue"),
    meta: {
      title: `${BRAND} - FAQs`
    }
  }
];
