import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";

import { deleteBoirForm, getAllBoirForms, getAvailableBoirFormCount } from "@/api/boir";
import { getDiyReturns } from "@/api/diy";
import {
  Get4868,
  Get7004,
  GetAll4868,
  GetAll7004,
  New4868,
  New7004,
  Save4868Full,
  Save4868Step2,
  Save4868Step3,
  Save4868Step4,
  Save4868Step5,
  Save4868Step6,
  Save4868Step7,
  Save7004Full,
  Save7004Step2,
  Save7004Step3,
  Save7004Step4,
  Save7004Step5,
  Save7004Step6,
  Save7004Step7,
  Save7004Step8,
  Save7004Step9,
  setTaxFilingPlans
} from "@/api/forms";
import { GetSubscriptionStatuses, SubscribeShield } from "@/api/payments";
import { customerPlannerEvent } from "@/api/planner";
import { getAllDFYReturns, getVaultSections } from "@/api/returns";
import { ApplyCoupon } from "@/api/user";
import { getCourses, getDeadlines } from "@/api/utils";
import { cookieNames, currentYear, FIVE_DAYS_IN_MS, taxYear } from "@/config/constants";
import type {
  BackendAppControllerBoirForm,
  CommonTaxPlannerEvent,
  DfyTaxReturn,
  DiyTaxReturn,
  DtoF4868CreateRequest,
  DtoF4868FullUpdateRequest,
  DtoF4868Step2Request,
  DtoF4868Step3Request,
  DtoF4868Step4Request,
  DtoF4868Step5Request,
  DtoF4868Step6Request,
  DtoF4868Step7Request,
  DtoF7004CreateRequest,
  DtoF7004Step2Request,
  DtoF7004Step3Request,
  DtoF7004Step4Request,
  DtoF7004Step5Request,
  DtoF7004Step6Request,
  DtoF7004Step7Request,
  DtoF7004Step8Request,
  DtoF7004Step9Request,
  DtoF7004UpdateFormRequest,
  DtoForm4868,
  DtoForm7004,
  DtoProduct,
  DtoSubscriptionDataRecord,
  F4868TaxFilingPlansRequest,
  ResourcesClientPlannerEvent,
  ResourcesResourceVaultSection,
  UtilsCourse
} from "@/services/api";
import {
  CommonFormFilingType,
  CommonFormStatus,
  CommonPaymentStatus,
  CommonProductFocus,
  CommonProductTypes,
  CommonSubmissionStatus,
  CommonSubscriptionStatus,
  DfyPaymentStatus,
  DfyReturnType,
  DtoF4868CreateRequestFormChoiceEnum,
  DtoF7004CreateRequestFormChoiceEnum
} from "@/services/api";
import { useAuthStore } from "@/store/auth.store";
import { usePaymentsStore } from "@/store/payments.store";
import { useAppStateStore } from "@/store/state.store";
import type { CurrentForm, DtoDeadlines, ExtForm, Filing, FilingCard } from "@/store/types";
import { FilingPriority, isForm4868, isForm7004 } from "@/store/types";
import type { FilingDictionary } from "@/types/filing";
import { getCookie, setCookie } from "@/util/cookies";
import type { CatchError } from "@/util/error";
import { handleError } from "@/util/error";
import { valIfExists } from "@/util/format";
import { findDefaultForm, getEngineSource, getLocalDateFromString } from "@/util/helpers";
import { getLogger } from "@/util/logger";
import { sortFilings, unique } from "@/util/utils";

const logger = getLogger("Store: Forms");

const currentForm = getCookie<CurrentForm>(cookieNames.currentForm);

export const defaultForm: CurrentForm = {
  uid: null,
  type: "personal",
  both: false,
  boost: false,
  autoRenew: false,
  processedProgress: 0,
  processedLoopingProgress: 0,
  processedTarget: 10
};

export const getForm4868DefaultState = (): DtoForm4868 => ({
  ack_errors: [],
  address_line_1: "",
  address_line_2: "",
  amount_owed: 0,
  amount_payed: 0,
  city: "",
  created_at: "",
  filing_type: CommonFormFilingType.FormFilingTypeSingle,
  fills_both: false,
  finished: false,
  first_name: "",
  form_1040: false,
  form_status: CommonFormStatus.FormStatusNew,
  form_type: "",
  high_priority: false,
  id: "",
  is_accepted: false,
  is_acknowledged: false,
  is_form_complete: false,
  is_locked: false,
  is_out_of_country: false,
  is_payed: false,
  is_queued: false,
  is_submitted: false,
  force_military: false,
  force_zip: false,
  last_name: "",
  payment_status: CommonPaymentStatus.PaymentStatusNone,
  postmark: "",
  ssn: "",
  state: "",
  submission_status: CommonSubmissionStatus.SubmissionStatusNone,
  timestamp: "",
  updated_at: "",
  version: 0,
  year: 0,
  zip: "",
  deadline: {
    extended_filing_deadline: "",
    filing_deadline: ""
  }
});

export const getForm7004DefaultState = (): DtoForm7004 => ({
  ack_errors: [],
  address_line_1: "",
  address_line_2: "",
  business_tin: "",
  business_type: undefined,
  city: "",
  company_code: "",
  company_name: "",
  created_at: "",
  finished: false,
  follows_fiscal_year: true,
  form_code: "",
  form_name: "",
  form_status: CommonFormStatus.FormStatusNew,
  form_type: "",
  high_priority: false,
  id: "",
  is_accepted: false,
  is_acknowledged: false,
  is_form_complete: false,
  is_locked: false,
  is_payed: false,
  is_queued: false,
  is_submitted: false,
  force_military: false,
  force_zip: false,
  no_office_in_us: false,
  parent_of_group: false,
  payment_status: CommonPaymentStatus.PaymentStatusNone,
  reg_160815: false,
  short_tax_year: false,
  state: "",
  submission_status: CommonSubmissionStatus.SubmissionStatusNone,
  tentative_total_tax: 0,
  total_payments: 0,
  updated_at: "",
  year: 0,
  zip: "",
  deadline: {
    extended_filing_deadline: "",
    filing_deadline: ""
  }
});

export interface FormsState {
  form: CurrentForm;
  forms4868: DtoForm4868[];
  forms4868Loaded: boolean;
  forms7004: DtoForm7004[];
  forms7004Loaded: boolean;
  form4868Data: DtoForm4868;
  form7004Data: DtoForm7004;
  funnelTitle?: string | null;
  currentOffer?: string;
  diyReturns: DiyTaxReturn[];
  subscriptionStatuses: DtoSubscriptionDataRecord[];
  vaultSections: ResourcesResourceVaultSection[];
  dfyReturns: DfyTaxReturn[];
  boirForms: BackendAppControllerBoirForm[];
  availableBoirFormCount: number;
  courses: UtilsCourse[];
  deadlines: DtoDeadlines;
}

type AddPriorityFunc = (filingCard: FilingCard) => Filing;

type FilterByYearFunc = (filingCard: FilingCard) => boolean;

const addPriority = (priority: FilingPriority): AddPriorityFunc => {
  return (filingCard: FilingCard) => ({
    priority,
    ...filingCard
  });
};

const filterByYear = (year: number): FilterByYearFunc => {
  return (filing) => {
    if ("year" in filing) {
      return filing.year === year;
    }

    if ("tax_year" in filing) {
      return Number(filing.tax_year) === year;
    }

    return false;
  };
};

export const useFormsStore = defineStore("forms", {
  state: (): FormsState => ({
    form: currentForm ?? defaultForm,
    forms4868: [],
    forms4868Loaded: false,
    forms7004: [],
    forms7004Loaded: false,
    form4868Data: { ...getForm4868DefaultState() },
    form7004Data: { ...getForm7004DefaultState() },
    currentOffer: undefined,
    subscriptionStatuses: [],
    diyReturns: [],
    vaultSections: [],
    dfyReturns: [],
    boirForms: [],
    availableBoirFormCount: 0,
    courses: [],
    deadlines: {
      Form1041: {
        extended_filing_deadline: "",
        filing_deadline: ""
      },
      Form1065: {
        extended_filing_deadline: "",
        filing_deadline: ""
      },
      Form1120: {
        extended_filing_deadline: "",
        filing_deadline: ""
      },
      Form1120F: {
        extended_filing_deadline: "",
        filing_deadline: ""
      },
      Form1120S: {
        extended_filing_deadline: "",
        filing_deadline: ""
      },
      Form4868: {
        extended_filing_deadline: "",
        filing_deadline: ""
      }
    }
  }),
  getters: {
    formId(): string | null {
      return this.form.uid;
    },
    filings(state): Filing[] {
      return [
        ...state.forms4868.map(addPriority(FilingPriority.Low)),
        ...state.forms7004.map(addPriority(FilingPriority.Low)),
        ...state.diyReturns.map(addPriority(FilingPriority.Low)),
        ...state.dfyReturns.map(addPriority(FilingPriority.High))
      ];
    },
    filingsByYear(): FilingDictionary[] {
      const years: number[] = this.filings
        .map((filing) => {
          if ("year" in filing) {
            return filing.year;
          }

          if ("tax_year" in filing) {
            return Number(filing.tax_year);
          }

          return 0;
        })
        .filter(unique);

      return years.map((year) => ({
        year,
        filings: sortFilings(this.filings.filter(filterByYear(year)))
      }));
    },
    boirFilingsByYear(state): Record<number, BackendAppControllerBoirForm[]> {
      return Object.groupBy(
        state.boirForms,
        (form) => getLocalDateFromString(form.created_at).getFullYear() - 1
      ) as Record<number, BackendAppControllerBoirForm[]>;
    },
    incompleteForms(state): ExtForm[] {
      return [...state.forms4868, ...state.forms7004].filter((f) => {
        return f.year === taxYear && f.form_status === CommonFormStatus.FormStatusNew;
      });
    },
    hasUnfinishedForms(state): boolean {
      const forms: ExtForm[] = [...state.forms4868, ...state.forms7004].filter((x) => x.year === taxYear);

      return forms.some((x) => x.submission_status === CommonSubmissionStatus.SubmissionStatusAckRecoverableFailure);
    },
    defaultForm(state): ExtForm | undefined {
      const currentTaxYearFilings = this.filingsByYear.find((filing) => filing.year === taxYear)?.filings ?? [];
      const currentTaxYearForms = currentTaxYearFilings.filter((filing) => isForm4868(filing) || isForm7004(filing));

      if (!currentTaxYearForms.length) {
        return undefined;
      }

      if (state.form.uid) {
        const currentForm = currentTaxYearForms.find((form) => form.id === state.form.uid);

        if (currentForm) return currentForm;
      }

      return findDefaultForm(currentTaxYearForms);
    },
    currentForm(state): ExtForm | undefined {
      const formType = state.form.type;

      if (formType === "personal") {
        return state.form4868Data;
      } else if (formType === "business") {
        return state.form7004Data;
      }
      return undefined;
    },
    currentFormData(state): ExtForm {
      let data: ExtForm;

      if (state.form.type === "personal") {
        data = state.form4868Data;
      } else {
        data = state.form7004Data;
      }

      data.form_type = state.form.type;
      return data;
    },
    processedProgress(state): number {
      return state.form.processedProgress;
    },
    processedLoopingProgress(state): number {
      return state.form.processedLoopingProgress;
    },
    processedTarget(state): number {
      return state.form.processedTarget;
    },
    defaultDfyPersonalDepositId(state): string | null {
      return (
        state.dfyReturns.find(
          (dfyReturn) =>
            dfyReturn.payment_status === DfyPaymentStatus.PaymentStatusPartial &&
            dfyReturn.return_type === DfyReturnType.ReturnTypePersonal
        )?.id ?? null
      );
    },
    defaultDfyBusinessDepositId(state): string | null {
      return (
        state.dfyReturns.find(
          (dfyReturn) =>
            dfyReturn.payment_status === DfyPaymentStatus.PaymentStatusPartial &&
            dfyReturn.return_type === DfyReturnType.ReturnTypeBusiness
        )?.id ?? null
      );
    },
    coursesByCategory(state): Record<string, UtilsCourse[]> {
      const { courses } = state;

      if (!courses.length) return {};

      return Object.groupBy(courses, (course: UtilsCourse) => course.category) as Record<string, UtilsCourse[]>;
    },
    bundleSubscriptions(state): DtoSubscriptionDataRecord[] {
      return state.subscriptionStatuses.filter((s) => s.subscription_type === CommonProductTypes.ProductTypesBundle);
    },
    shieldSubscriptions(state): DtoSubscriptionDataRecord[] {
      return state.subscriptionStatuses.filter((s) => s.subscription_type === CommonProductTypes.ProductTypesShield);
    },
    dashboardAlertType(): string {
      if (
        this.bundleSubscriptions.some(
          (subscription) => subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusExpired
        ) ||
        this.shieldSubscriptions.some(
          (subscription) => subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusExpired
        )
      ) {
        return "warning";
      }

      if (
        this.bundleSubscriptions.some(
          (subscription) =>
            subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusPaymentFailed
        ) ||
        this.shieldSubscriptions.some(
          (subscription) =>
            subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusPaymentFailed
        )
      ) {
        return "error";
      }

      return "none";
    },
    dashboardAlertReason(): string {
      if (
        this.bundleSubscriptions.some(
          (subscription) =>
            subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusExpired ||
            subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusPaymentFailed
        )
      ) {
        return "bundle";
      }

      if (
        this.shieldSubscriptions.some(
          (subscription) =>
            subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusExpired ||
            subscription.subscription_status === CommonSubscriptionStatus.SubscriptionStatusPaymentFailed
        )
      ) {
        return "shield";
      }

      return "none";
    },
    canceledSubscriptions(state): DtoSubscriptionDataRecord[] {
      return state.subscriptionStatuses.filter(
        (s) => s.subscription_status === CommonSubscriptionStatus.SubscriptionStatusCancelled
      );
    },
    activeSubscriptions(state): DtoSubscriptionDataRecord[] {
      return state.subscriptionStatuses.filter(
        (s) => s.subscription_status === CommonSubscriptionStatus.SubscriptionStatusYes
      );
    },
    canceledFilesmart(): DtoSubscriptionDataRecord | undefined {
      return this.canceledSubscriptions.find((s) => s.subscription_type === CommonProductTypes.ProductTypesBundle);
    },
    canceledShield(): DtoSubscriptionDataRecord | undefined {
      return this.canceledSubscriptions.find((s) => s.subscription_type === CommonProductTypes.ProductTypesShield);
    },
    hasThisTaxYearDiyReturn(state): boolean {
      return state.diyReturns.some((filing) => filing.tax_year === taxYear && filing.id && filing.is_enabled);
    },
    hasThisTaxYearDfyReturn(state): boolean {
      return state.dfyReturns.some(
        (filing) =>
          filing.tax_year === taxYear && filing.id && filing.payment_status === DfyPaymentStatus.PaymentStatusPaid
      );
    },
    hasThisTaxYearExtension(state): boolean {
      return [...state.forms4868, ...state.forms7004].some(
        (filing) => filing.year === taxYear && filing.id && filing.is_form_complete
      );
    },
    isTaxPrepEnabled(): boolean {
      const authStore = useAuthStore();
      const { product_focus } = authStore.userData;
      const hasRequiredFocus =
        product_focus === CommonProductFocus.ProductFocusExtension ||
        product_focus === CommonProductFocus.ProductFocusNone;
      const extensionFocusWithoutExtensionFilled =
        product_focus === CommonProductFocus.ProductFocusExtension && !this.hasThisTaxYearExtension;

      return (
        hasRequiredFocus &&
        !this.hasThisTaxYearDiyReturn &&
        !this.hasThisTaxYearDfyReturn &&
        !extensionFocusWithoutExtensionFilled
      );
    }
  },
  actions: {
    setForm(data: CurrentForm) {
      this.form = data;
      setCookie(cookieNames.currentForm, data, FIVE_DAYS_IN_MS);
    },
    setFormOptions(payload: Partial<CurrentForm>) {
      const form = { ...this.form, ...payload };

      this.setForm(form);
    },
    updateBoost() {
      const { boost } = usePaymentsStore();
      this.setFormOptions({ boost });
    },
    setFunnelTitle(data?: string | null) {
      this.funnelTitle = data;
    },
    resetForm() {
      this.setForm(defaultForm);
    },
    resetFormsData() {
      this.form4868Data = getForm4868DefaultState();
      this.form7004Data = getForm7004DefaultState();
    },
    setCourses(data: UtilsCourse[]) {
      this.courses = data;
    },
    async applyCoupon(cpn: string) {
      const response = await ApplyCoupon(cpn);
      const appStateStore = useAppStateStore();

      await appStateStore.getSettings();

      return response;
    },
    async getCourses() {
      try {
        const courses = await getCourses();

        this.setCourses(courses);
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    async getVaultSections() {
      const sections = await getVaultSections();

      this.vaultSections = sections;
    },
    async getDiyReturns() {
      try {
        logger.info("Get diy column tax action call...");
        const diyReturns = await getDiyReturns();

        this.setDiyReturns(diyReturns);
      } catch (e) {
        Sentry.captureMessage(`Error trying to get filings: ${JSON.stringify(e)}`);
      }
    },
    async getDfyReturns() {
      const dfyReturns = await getAllDFYReturns();

      this.dfyReturns = dfyReturns;
    },
    async getBoirForms() {
      const { available_form_count } = await getAvailableBoirFormCount();
      const boirForms = await getAllBoirForms();

      this.boirForms = boirForms;
      this.availableBoirFormCount = available_form_count ?? 0;
    },
    async deleteBoirForm(id: string) {
      await deleteBoirForm(id);
      await this.getBoirForms();
    },
    setCurrentOffer(offer?: string) {
      this.currentOffer = offer;
    },
    getFilingsByYear(year: number): Filing[] {
      return this.filingsByYear.find((filing) => filing.year === year)?.filings ?? [];
    },
    getBoirFilingsByYear(year: number): BackendAppControllerBoirForm[] {
      return this.boirFilingsByYear[year] ?? [];
    },
    getFilingsExceptYear(year: number): Filing[] {
      return (
        this.filingsByYear
          .filter((filing) => filing.year !== year)
          ?.map((dictionary) => dictionary.filings)
          .flat() ?? []
      );
    },
    getBoirFormsExceptYear(year: number): BackendAppControllerBoirForm[] {
      return Object.entries(this.boirFilingsByYear)
        .filter(([formYear]) => year !== Number(formYear))
        .reduce((acc, [, forms]) => acc.concat(forms), [] as BackendAppControllerBoirForm[]);
    },
    // TODO: used to update purchases prop of ExtForm (this field is not exist)
    updateCurrentForm(data: Partial<ExtForm> | Record<"purchases", DtoProduct[]>) {
      const formType = this.form.type;
      const currentForm = formType === "personal" ? this.form4868Data : this.form7004Data;

      if (isForm4868(currentForm)) {
        this.form4868Data = { ...currentForm, ...data };
      }
      if (isForm7004(currentForm)) {
        this.form7004Data = { ...currentForm, ...data };
      }
    },
    changeCurrentForm(form: CurrentForm) {
      this.form = { ...this.form, ...form };
    },
    setDiyReturns(diyReturns: DiyTaxReturn[]) {
      this.diyReturns = [...diyReturns];
    },
    setSubscriptionStatuses(data: DtoSubscriptionDataRecord[]) {
      this.subscriptionStatuses = data;
    },
    setProcessedProgress(value: number) {
      const form = this.form;

      form.processedProgress = value;

      this.setForm(form);
    },
    setDeadlines(data: DtoDeadlines) {
      this.deadlines = data;
    },
    async getDeadlines() {
      const deadlines = await getDeadlines();
      const currentYearDeadlines = deadlines[currentYear] ?? {};

      const newDealines = Object.keys(currentYearDeadlines).reduce((acc, key) => {
        const [form] = key.split(/(Deadline|Extension)/);
        acc[form] = acc[form] ?? {};
        acc[form][key.includes("Deadline") ? "filing_deadline" : "extended_filing_deadline"] =
          currentYearDeadlines[key];
        return acc;
      }, {});

      this.setDeadlines(newDealines);
    },
    setProcessedLoopingProgress(value: number) {
      const form = this.form;

      form.processedLoopingProgress = value;

      this.setForm(form);
    },
    setProcessedTarget(value: number) {
      const form = this.form;

      form.processedTarget = value;

      this.setForm(form);
    },
    async getSubscriptionStatuses() {
      try {
        const data = await GetSubscriptionStatuses();

        this.setSubscriptionStatuses(data ?? []);
      } catch (e) {
        Sentry.captureException(e);
      }
    },
    async customerPlannerEvent(event: CommonTaxPlannerEvent): Promise<void> {
      const request: ResourcesClientPlannerEvent = {
        event
      };

      try {
        await customerPlannerEvent(request);
      } catch (error) {
        Sentry.captureMessage(
          `Pass customer planner event request error. Request: ${JSON.stringify(request)}. Response: ${JSON.stringify(error)}`
        );
      }
    },
    async setTaxFilingPlans(request: F4868TaxFilingPlansRequest): Promise<void> {
      try {
        if (!request.filing_method && !request.filing_time) {
          return;
        }

        await setTaxFilingPlans(request);
      } catch (error) {
        Sentry.captureMessage(
          `Set tax filing plans request error. Request: ${JSON.stringify(request)}. Response: ${JSON.stringify(error)}`
        );
      }
    },
    async shieldSubscribe(productSku: string) {
      try {
        await SubscribeShield({
          action_url: window.location.href,
          source: getEngineSource(),
          product_sku: productSku
        });
      } catch (e: CatchError) {
        handleError(e);
      }
    },
    set4668Forms(data: DtoForm4868[]) {
      this.forms4868 = data;
      this.forms4868Loaded = true;
    },
    set7004Forms(data: DtoForm7004[]) {
      this.forms7004 = data;
      this.forms7004Loaded = true;
    },
    async get4868FormData() {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      const formData = await Get4868(this.form.uid);

      if (formData) {
        this.set4868FormStep2Data(formData);
        this.set4868FormStep3Data(formData);
        this.set4868FormStep4Data(formData);
        this.set4868FormStep5Data(formData);
        this.set4868FormStep6Data(formData);
        this.set4868FormStep7Data(formData);
        this.set4868FormStatusesData(formData);
      }
    },
    async get7004FormData() {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      const formData = await Get7004(this.form.uid);

      if (formData) {
        this.set7004FormStep2Data(formData);
        this.set7004FormStep3Data(formData);
        this.set7004FormStep4Data(formData);
        this.set7004FormStep5Data(formData);
        this.set7004FormStep6Data(formData);
        this.set7004FormStep7Data(formData);
        this.set7004FormStep8Data(formData);
        this.set7004FormStep9Data(formData);
        this.set7004FormStatusesData(formData);
      }
    },
    async new4868Form(params: DtoF4868CreateRequest) {
      const authStore = useAuthStore();

      this.set4868FormStep2Data(params);

      // 11/24/2023 backend change, it currently ignores year param
      // and takes configured tax year from the settings instead
      params.year = taxYear;
      params.device_id = authStore.deviceId;

      const mode = getCookie(cookieNames.mode);

      if (mode) {
        params.form_choice = mode as DtoF4868CreateRequestFormChoiceEnum;
      } else {
        params.form_choice = DtoF4868CreateRequestFormChoiceEnum.Personal;
      }

      const data = await New4868(params);

      if (data) {
        this.setForm({
          uid: data.form_id,
          type: "personal",
          both: data.fills_both,
          boost: false,
          autoRenew: false,
          processedProgress: 0,
          processedLoopingProgress: 0,
          processedTarget: 10
        });
      }
    },
    async new7004Form(params: DtoF7004CreateRequest) {
      this.set7004FormStep2Data(params);

      const authStore = useAuthStore();

      params.year = taxYear;
      params.form_choice = DtoF7004CreateRequestFormChoiceEnum.Business;
      params.device_id = authStore.deviceId;

      const data = await New7004(params, authStore.userData);

      if (data) {
        this.setForm({
          uid: data?.form_id,
          type: "business",
          both: false,
          boost: false,
          autoRenew: false,
          processedProgress: 0,
          processedLoopingProgress: 0,
          processedTarget: 10
        });
      }
    },
    async save4868FormStep2(params: DtoF4868Step2Request) {
      if (!this.form.uid) {
        logger.log("No form selected");
        return;
      }

      this.set4868FormStep2Data(params);
      await Save4868Step2(this.form.uid, params);
    },
    async save4868FormStep3(params: DtoF4868Step3Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set4868FormStep3Data(params);

      await Save4868Step3(this.form.uid, params);
    },
    async save4868FormStep4(params: DtoF4868Step4Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set4868FormStep4Data(params);

      await Save4868Step4(this.form.uid, params);
    },
    async save4868FormStep5(params: DtoF4868Step5Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set4868FormStep5Data(params);

      await Save4868Step5(this.form.uid, params);
    },
    async save4868FormStep6(params: DtoF4868Step6Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set4868FormStep6Data(params);

      await Save4868Step6(this.form.uid, params);
    },
    async save4868FormStep7(params: DtoF4868Step7Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set4868FormStep7Data(params);

      await Save4868Step7(this.form.uid, params);
    },
    async save4868FormStep8(params: DtoF4868FullUpdateRequest) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      const authStore = useAuthStore();

      this.set4868FormStep2Data(params);
      this.set4868FormStep3Data(params);
      this.set4868FormStep4Data(params);
      this.set4868FormStep5Data(params);
      this.set4868FormStep6Data(params);
      this.set4868FormStep7Data(params);

      await Save4868Full(this.form.uid, { ...params, device_id: authStore.deviceId });

      this.set4868FormComplete();
    },
    async save7004FormStep2(params: DtoF7004Step2Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set7004FormStep2Data(params);

      await Save7004Step2(this.form.uid, params);
    },
    async save7004FormStep3(params: DtoF7004Step3Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set7004FormStep3Data(params);

      await Save7004Step3(this.form.uid, params);
    },
    async save7004FormStep4(params: DtoF7004Step4Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set7004FormStep4Data(params);

      await Save7004Step4(this.form.uid, params);
    },
    async save7004FormStep5(params: DtoF7004Step5Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set7004FormStep5Data(params);

      await Save7004Step5(this.form.uid, params);
    },
    async save7004FormStep6(params: DtoF7004Step6Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set7004FormStep6Data(params);

      await Save7004Step6(this.form.uid, params);
    },
    async save7004FormStep7(params: DtoF7004Step7Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set7004FormStep7Data(params);

      await Save7004Step7(this.form.uid, params);
    },
    async save7004FormStep8(params: DtoF7004Step8Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set7004FormStep8Data(params);

      await Save7004Step8(this.form.uid, {
        tentative_total_tax: params.tentative_total_tax,
        total_payments: params.total_payments
      });
    },
    async save7004FormStep9(params: DtoF7004Step9Request) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set7004FormStep9Data(params);

      await Save7004Step9(this.form.uid, params);
    },
    async save7004FormStep10(params: DtoF7004UpdateFormRequest) {
      if (!this.form?.uid) {
        logger.log("No form selected");
        return;
      }

      this.set7004FormStep2Data(params);
      this.set7004FormStep3Data(params);
      this.set7004FormStep4Data(params);
      this.set7004FormStep5Data(params);
      this.set7004FormStep6Data(params);
      this.set7004FormStep7Data(params);
      this.set7004FormStep8Data(params);
      this.set7004FormStep9Data(params);

      await Save7004Full(this.form.uid, params);

      this.set7004FormComplete();
    },
    set4868FormStep2Data(data: Partial<DtoF4868CreateRequest>) {
      this.form4868Data.first_name = valIfExists(this.form4868Data.first_name, data.first_name);
      this.form4868Data.last_name = valIfExists(this.form4868Data.last_name, data.last_name);

      if (data?.form_choice === DtoF4868CreateRequestFormChoiceEnum.Both && this.form) {
        this.form.both = valIfExists(this.form4868Data.fills_both, true);
      }
    },
    set4868FormStep3Data(data: DtoF4868Step3Request) {
      this.form4868Data.city = valIfExists(this.form4868Data.city, data.city);
      this.form4868Data.state = valIfExists(this.form4868Data.state, data.state);
      this.form4868Data.zip = valIfExists(this.form4868Data.zip, data.zip);
      this.form4868Data.address_line_1 = valIfExists(this.form4868Data.address_line_1, data.address_line_1);
      this.form4868Data.address_line_2 = valIfExists(this.form4868Data.address_line_2, data.address_line_2);
      this.form4868Data.force_zip = valIfExists(this.form4868Data.force_zip, data.force_zip);
      this.form4868Data.force_military = valIfExists(this.form4868Data.force_military, data.force_military);
    },
    set4868FormStep4Data(data: DtoF4868Step4Request) {
      this.form4868Data.filing_type = valIfExists(this.form4868Data.filing_type, data.filing_type);
      this.form4868Data.spouse_first_name = valIfExists(this.form4868Data.spouse_first_name, data.spouse_first_name);
      this.form4868Data.spouse_last_name = valIfExists(this.form4868Data.spouse_last_name, data.spouse_last_name);
    },
    set4868FormStep5Data(data: DtoF4868Step5Request) {
      this.form4868Data.form_1040 = valIfExists(this.form4868Data.form_1040, data.form_1040);
      this.form4868Data.is_out_of_country = valIfExists(this.form4868Data.is_out_of_country, data.is_out_of_country);
    },
    set4868FormStep6Data(data: DtoF4868Step6Request) {
      this.form4868Data.amount_owed = valIfExists(this.form4868Data.amount_owed, data.amount_owed);
      this.form4868Data.amount_payed = valIfExists(this.form4868Data.amount_payed, data.amount_payed);
    },
    set4868FormStep7Data(data: DtoF4868Step7Request) {
      this.form4868Data.ssn = valIfExists(this.form4868Data.ssn, data.ssn);
      this.form4868Data.spouse_ssn = valIfExists(this.form4868Data.spouse_ssn, data.spouse_ssn);
    },
    set4868FormStatusesData(data: DtoForm4868) {
      this.form4868Data.year = valIfExists(this.form4868Data.year, data.year);
      this.form4868Data.ack_errors = valIfExists(this.form4868Data.ack_errors, data.ack_errors);
      this.form4868Data.form_status = valIfExists(this.form4868Data.form_status, data.form_status);
      this.form4868Data.payment_status = valIfExists(this.form4868Data.payment_status, data.payment_status);
      this.form4868Data.submission_status = valIfExists(this.form4868Data.submission_status, data.submission_status);
      this.form4868Data.postmark = valIfExists(this.form4868Data.postmark, data.postmark);
      this.form4868Data.is_accepted = valIfExists(this.form4868Data.is_accepted, data.is_accepted);
      this.form4868Data.is_acknowledged = valIfExists(this.form4868Data.is_acknowledged, data.is_acknowledged);
      this.form4868Data.is_form_complete = valIfExists(this.form4868Data.is_form_complete, data.is_form_complete);
      this.form4868Data.is_locked = valIfExists(this.form4868Data.is_locked, data.is_locked);
      this.form4868Data.is_payed = valIfExists(this.form4868Data.is_payed, data.is_payed);
      this.form4868Data.is_queued = valIfExists(this.form4868Data.is_queued, data.is_queued);
      this.form4868Data.is_submitted = valIfExists(this.form4868Data.is_submitted, data.is_submitted);
      this.form4868Data.submission_created_at = valIfExists(
        this.form4868Data.submission_created_at,
        data.submission_created_at
      );

      /*
      this.form4868Data.shield_status = valIfExists(this.form4868Data.shield_status, data.shield_status);
      this.form4868Data.bundle_status = valIfExists(this.form4868Data.bundle_status, data.bundle_status);
      this.form4868Data.auto_renew_status = valIfExists(this.form4868Data.auto_renew_status, data.auto_renew_status);
      this.form4868Data.auto_renew_discount = valIfExists(
        this.form4868Data.auto_renew_discount,
        data.auto_renew_discount
      );
      this.form4868Data.purchases = valIfExists(this.form4868Data.purchases, data.purchases);

      this.form4868Data.auto_renew_subscribed_at = valIfExists(
        this.form4868Data.auto_renew_subscribed_at,
        data.auto_renew_subscribed_at
      );

      */

      this.form4868Data.high_priority = valIfExists(this.form4868Data.high_priority, data.high_priority);
      this.form4868Data.id = data.id;

      this.form4868Data.deadline = valIfExists(this.form4868Data.deadline, data.deadline);
    },
    set4868FormComplete() {
      this.form4868Data.is_form_complete = true;
    },
    set7004FormStep2Data(data: DtoForm7004 | DtoF7004Step2Request) {
      this.form7004Data.business_type = valIfExists(this.form7004Data.business_type, data.business_type);
      this.form7004Data.form_name = valIfExists(this.form7004Data.form_name, data.form_name);
      this.form7004Data.form_code = valIfExists(this.form7004Data.form_code, data.form_code);
      this.form7004Data.surname_of_deceased = valIfExists(
        this.form7004Data.surname_of_deceased,
        data.surname_of_deceased
      );
    },
    set7004FormStep3Data(data: DtoF7004Step3Request) {
      this.form7004Data.company_name = valIfExists(this.form7004Data.company_name, data.company_name);
      this.form7004Data.company_code = valIfExists(this.form7004Data.company_code, data.company_code);
    },
    set7004FormStep4Data(data: DtoF7004Step4Request) {
      this.form7004Data.city = valIfExists(this.form7004Data.city, data.city);
      this.form7004Data.state = valIfExists(this.form7004Data.state, data.state);
      this.form7004Data.zip = valIfExists(this.form7004Data.zip, data.zip);
      this.form7004Data.address_line_1 = valIfExists(this.form7004Data.address_line_1, data.address_line_1);
      this.form7004Data.address_line_2 = valIfExists(this.form7004Data.address_line_2, data.address_line_2);
      this.form7004Data.force_zip = valIfExists(this.form7004Data.force_zip, data.force_zip);
      this.form7004Data.force_military = valIfExists(this.form7004Data.force_military, data.force_military);
    },
    set7004FormStep5Data(data: DtoF7004Step5Request) {
      this.form7004Data.no_office_in_us = valIfExists(this.form7004Data.no_office_in_us, data.no_office_in_us);
      this.form7004Data.parent_of_group = valIfExists(this.form7004Data.parent_of_group, data.parent_of_group);
      this.form7004Data.reg_160815 = valIfExists(this.form7004Data.reg_160815, data.reg_160815);
    },
    set7004FormStep6Data(data: DtoF7004Step6Request) {
      this.form7004Data.follows_fiscal_year = valIfExists(
        this.form7004Data.follows_fiscal_year,
        data.follows_fiscal_year
      );
      this.form7004Data.year_starts_at = valIfExists(this.form7004Data.year_starts_at, data.year_starts_at);
      this.form7004Data.year_ends_at = valIfExists(this.form7004Data.year_ends_at, data.year_ends_at);
    },
    set7004FormStep7Data(data: DtoF7004Step7Request) {
      this.form7004Data.short_tax_year = valIfExists(this.form7004Data.short_tax_year, data.short_tax_year);
      this.form7004Data.short_tax_year_reason = valIfExists(
        this.form7004Data.short_tax_year_reason,
        data.short_tax_year_reason
      );
      this.form7004Data.short_tax_year_text = valIfExists(
        this.form7004Data.short_tax_year_text,
        data.short_tax_year_text
      );
    },
    set7004FormStep8Data(data: DtoF7004Step8Request) {
      this.form7004Data.tentative_total_tax = valIfExists(
        this.form7004Data.tentative_total_tax,
        data.tentative_total_tax
      );
      this.form7004Data.total_payments = valIfExists(this.form7004Data.total_payments, data.total_payments);
    },
    set7004FormStep9Data(data: DtoF7004Step9Request) {
      this.form7004Data.business_tin = valIfExists(this.form7004Data.business_tin, data.business_tin);
    },
    set7004FormStatusesData(data: DtoForm7004) {
      this.form7004Data.year = valIfExists(this.form7004Data.year, data.year);
      this.form7004Data.ack_errors = valIfExists(this.form7004Data.ack_errors, data.ack_errors);
      this.form7004Data.form_status = valIfExists(this.form7004Data.form_status, data.form_status);
      this.form7004Data.payment_status = valIfExists(this.form7004Data.payment_status, data.payment_status);
      this.form7004Data.submission_status = valIfExists(this.form7004Data.submission_status, data.submission_status);
      this.form7004Data.postmark = valIfExists(this.form7004Data.postmark, data.postmark);
      this.form7004Data.is_accepted = valIfExists(this.form7004Data.is_accepted, data.is_accepted);
      this.form7004Data.is_acknowledged = valIfExists(this.form7004Data.is_acknowledged, data.is_acknowledged);
      this.form7004Data.is_form_complete = valIfExists(this.form7004Data.is_form_complete, data.is_form_complete);
      this.form7004Data.is_locked = valIfExists(this.form7004Data.is_locked, data.is_locked);
      this.form7004Data.is_payed = valIfExists(this.form7004Data.is_payed, data.is_payed);
      this.form7004Data.is_queued = valIfExists(this.form7004Data.is_queued, data.is_queued);
      this.form7004Data.is_submitted = valIfExists(this.form7004Data.is_submitted, data.is_submitted);
      this.form7004Data.submission_created_at = valIfExists(
        this.form7004Data.submission_created_at,
        data.submission_created_at
      );
      this.form7004Data.high_priority = valIfExists(this.form7004Data.high_priority, data.high_priority);
      /*
      this.form7004Data.shield_status = valIfExists(this.form7004Data.shield_status, data.shield_status);
      this.form7004Data.bundle_status = valIfExists(this.form7004Data.bundle_status, data.bundle_status);
      this.form7004Data.auto_renew_status = valIfExists(this.form7004Data.auto_renew_status, data.auto_renew_status);
      this.form7004Data.auto_renew_discount = valIfExists(
        this.form7004Data.auto_renew_discount,
        data.auto_renew_discount
      );
      */
      this.form7004Data.id = data.id;
      /*
        this.form7004Data.auto_renew_subscribed_at = valIfExists(
          this.form7004Data.auto_renew_subscribed_at,
          data.auto_renew_subscribed_at
        );
      */
      this.form7004Data.deadline = valIfExists(this.form7004Data.deadline, data.deadline);
    },
    set7004FormComplete() {
      this.form7004Data.is_form_complete = true;
    },
    async get4868Form(id: string) {
      try {
        const currentForm = await Get4868(id);

        if (!currentForm) return;

        const forms = this.forms4868.map((form) => (form.id === currentForm.id ? currentForm : form));

        this.set4668Forms(forms ?? []);
      } catch (error) {
        Sentry.captureMessage(`Get4868Form failed, id: ${id}, error: ${JSON.stringify(error)}`);
      }
    },
    async get7004Form(id: string) {
      try {
        const currentForm = await Get7004(id);

        if (!currentForm) return;

        const forms = this.forms7004.map((form) => (form.id === currentForm.id ? currentForm : form));

        this.set7004Forms(forms ?? []);
      } catch (error) {
        Sentry.captureMessage(`Get7004Form failed, id: ${id}, error: ${JSON.stringify(error)}`);
      }
    },
    async getAll4868Forms() {
      this.forms4868Loaded = false;

      const forms = await GetAll4868();

      this.set4668Forms(forms ?? []);

      return forms ?? [];
    },
    async getAll7004Forms() {
      this.forms7004Loaded = false;

      const forms = await GetAll7004();

      this.set7004Forms(forms ?? []);

      return forms ?? [];
    }
  }
});
