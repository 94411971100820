import * as Sentry from "@sentry/vue";
import { defineStore } from "pinia";

import {
  ChargeProfile,
  EditBundleSubscription,
  EditShieldSubscription,
  GetAllActiveUserPurchases
} from "@/api/payments";
import { getProductsShort } from "@/api/utils";
import { useEventBus } from "@/composables/use-event-bus";
import { useModalsState } from "@/composables/use-modals-state";
import { cookieNames, currentYear, MONTH_IN_MS, THREE_DAYS_IN_MS } from "@/config/constants";
import type {
  DtoCart,
  DtoCartItem,
  DtoProduct,
  PurchasesPurchase,
  PurchasesUserPurchaseResponse,
  UtilsProductShort
} from "@/services/api";
import { CommonProductTypes, CommonSubscriptionStatus } from "@/services/api";
import { useFormsStore } from "@/store/forms.store";
import { useAppStateStore } from "@/store/state.store";
import type { DtoPurchaseSkuEnum, UserData } from "@/store/types";
import type { OpenPymentModalData, ProfilePaymentData } from "@/types/dashboard";
import { getCookie, removeCookie, setCookie } from "@/util/cookies";
import { getUUIDv4 } from "@/util/crypto";
import { getEngineSource } from "@/util/helpers";
import { addPurchaseToDataLayer } from "@/util/tracking";

import { useAuthStore } from "./auth.store";
import { useDiyStore } from "./diy.store";
import { usePaymentsStore } from "./payments.store";

export const getCartDataCookieName = (): string => {
  const userData = getCookie<UserData>(cookieNames.userData);
  const userId = userData?.id ?? "guest";
  return `${cookieNames.cartData}-${userId}`;
};

const invoiceId = getCookie(cookieNames.invoiceId);
const cartData = getCookie<DtoCart>(getCartDataCookieName());

export interface CartState {
  cart: DtoCart;
  purchases: PurchasesPurchase[];
  availableProductTypes: CommonProductTypes[];
  productsShort: UtilsProductShort[];
}

export const useCartStore = defineStore("cart", {
  state: (): CartState => ({
    cart: cartData ?? {
      invoice_id: invoiceId && invoiceId !== "" ? invoiceId : getUUIDv4(),
      purchases: []
    },
    purchases: [],
    availableProductTypes: [],
    productsShort: []
  }),
  getters: {
    totalCartPrice(state) {
      if (!state.cart) return 0;

      const appStateStore = useAppStateStore();

      let price = 0;

      const purchases: DtoCartItem[] = state.cart.purchases ?? [];
      const usedProducts: string[] = [];
      const hasFilesmart = state.cart.purchases.some(
        (purchase) =>
          appStateStore.settings.products?.find((product) => product.is_available && product.sku == purchase.sku)
            ?.product_type === CommonProductTypes.ProductTypesBundle
      );

      if (!appStateStore.settings.products) return price;

      appStateStore.settings.products.forEach((product: DtoProduct) => {
        if (!purchases.some((purchase) => purchase.sku === product.sku) || usedProducts.includes(product.sku ?? ""))
          return;
        if (
          product.has_trial ||
          product.product_type === CommonProductTypes.ProductTypesAutoExtend ||
          (hasFilesmart && product.product_type === CommonProductTypes.ProductTypesPriority)
        )
          return;

        const productQuantity = purchases.filter((purchase) => purchase.sku === product.sku).length;
        price += (product.price ?? 0) * productQuantity;
      });

      return price;
    },
    // Copy paste of prev method, but used to calculate base price (i.e. price without copuons and discounts
    totalCartOriginalPrice: (state): number => {
      if (!state.cart) return 0;

      const appStateStore = useAppStateStore();

      let price = 0;

      const purchases: DtoCartItem[] = state.cart.purchases ?? [];
      const usedProducts: string[] = [];

      if (!appStateStore.settings.products) return price;

      appStateStore.settings.products.forEach((product: DtoProduct) => {
        if (!purchases.some((purchase) => purchase.sku === product.sku) || usedProducts.includes(product.sku ?? ""))
          return;

        price += product.original_price ?? 0;
        usedProducts.push(product.sku ?? "");
      });

      return price;
    },
    getAvailableProductTypes(state): CommonProductTypes[] {
      return state.availableProductTypes;
    },
    inactivePurchases(state): PurchasesPurchase[] {
      return state.purchases.filter(
        (purchase) =>
          purchase.product_status === CommonSubscriptionStatus.SubscriptionStatusNo ||
          purchase.product_status === CommonSubscriptionStatus.SubscriptionStatusCancelled ||
          purchase.product_status === CommonSubscriptionStatus.SubscriptionStatusExpired
      );
    },
    bundlePurchases(state): PurchasesPurchase[] {
      return state.purchases.filter((p) => p.product_type === CommonProductTypes.ProductTypesBundle);
    },
    isBundleExpired(): boolean {
      return this.bundlePurchases.some(
        (p) =>
          p.product_status === CommonSubscriptionStatus.SubscriptionStatusPaymentFailed ||
          p.product_status === CommonSubscriptionStatus.SubscriptionStatusExpired
      );
    },
    allProducts(): DtoPurchaseSkuEnum[] {
      return this.purchases
        .filter(
          (purchase) =>
            purchase.purchased_at && new Date(purchase.purchased_at).getFullYear() === currentYear && purchase.sku
        )
        .map((purchase) => purchase.sku as DtoPurchaseSkuEnum)
        .filter(Boolean);
    },
    isPurchaseRepeated(state): boolean {
      const appStateStore = useAppStateStore();

      const thisYearPurchases = state.purchases.filter(
        (purchase) =>
          purchase.product_status === CommonSubscriptionStatus.SubscriptionStatusYes &&
          purchase.created_at &&
          purchase.sku === appStateStore.returnFocusOneTimeProduct && // Should only work for pr99 for now
          new Date(purchase.created_at).getFullYear() === currentYear
      );

      // Check if cart is empty as Array.prototype.every always returns true for an empty array
      if (state.cart.purchases.length === 0) {
        return false;
      }

      return state.cart.purchases.every((cartPurchase) =>
        thisYearPurchases.some((purchase) => purchase.sku === cartPurchase.sku)
      );
    }
  },
  actions: {
    isProductTypeAvailable(type: CommonProductTypes): boolean {
      return this.getAvailableProductTypes.includes(type);
    },
    syncCartData() {
      setCookie(getCartDataCookieName(), this.cart, THREE_DAYS_IN_MS);
    },
    getCartFromCookies() {
      const cartData = getCookie<DtoCart>(getCartDataCookieName());
      this.cart = cartData ?? {
        invoice_id: invoiceId && invoiceId !== "" ? invoiceId : getUUIDv4(),
        purchases: []
      };
    },
    setCartPurchases(cart: DtoCartItem[]) {
      this.cart.purchases = cart;
      this.syncCartData();
    },
    initCart() {
      const invoiceId = getUUIDv4();

      setCookie(cookieNames.invoiceId, invoiceId, MONTH_IN_MS);

      const cartData = {
        purchases: [],
        invoice_id: invoiceId
      };

      this.cart = cartData;
      this.syncCartData();
    },
    async cleanCart() {
      return new Promise<void>((resolve) => {
        this.excludeAllFormProducts();
        this.initCart();

        resolve();
      });
    },
    excludeAllFormProducts() {
      const appStateStore = useAppStateStore();
      const formsStore = useFormsStore();

      const settings = { ...appStateStore.settings };
      const cart = this.cart;

      // Enabling all disabled products instead of requesting newer settings
      // allows avoiding flickering or the offers after settings call returns results
      if (settings?.offer_pages?.[formsStore.form.type]) {
        const offers = settings.offer_pages[formsStore.form.type];
        settings.offer_pages[formsStore.form.type] = offers.map((of) => {
          if (of.products) {
            of.products = of.products.map((pr) => {
              if (cart.purchases?.some((purchase) => purchase.sku === pr.sku)) {
                pr.is_available = true;
              }
              return pr;
            });
          }
          return of;
        });
      }

      this.syncCartData();
      this.setCartPurchases([]);
    },
    excludeFormProducts(payload: DtoCartItem[]) {
      const { purchases } = this.cart;

      this.cart.purchases = [...(purchases ?? [])].filter(
        (purchase) => !payload.some((payload_item) => payload_item.sku === purchase.sku)
      );

      this.syncCartData();
    },
    updateFormProducts(payload: DtoCartItem[]) {
      const { purchases } = this.cart;

      this.cart.purchases = [
        ...(purchases ?? []),
        ...payload.filter((payload_item) => !purchases.some((purchase) => purchase.sku === payload_item.sku))
      ];

      this.syncCartData();
    },
    setAllActiveUserPurchases(data: PurchasesUserPurchaseResponse) {
      this.purchases = data.purchases;
      this.availableProductTypes = data.available_producttypes;
    },
    setProductsShort(data: UtilsProductShort[]) {
      this.productsShort = data;
    },
    async getProductsShort() {
      const coupon = getCookie<string>(cookieNames.coupon);

      console.log("get products short");

      const shortProducts = await getProductsShort(coupon ?? undefined);

      this.setProductsShort(shortProducts);

      console.log("got products short");
    },
    async getAllActiveUserPurchases() {
      const data = await GetAllActiveUserPurchases();

      if (data) {
        this.setAllActiveUserPurchases(data);
      }
    },
    async updateFormProductsDelayed(payload: DtoCartItem[]) {
      return new Promise<void>((resolve) => {
        this.updateFormProducts(payload);
        setTimeout(() => resolve(), 50);
      });
    },
    async excludeAllFormProductsDelayed() {
      return new Promise<void>((resolve) => {
        this.excludeAllFormProducts();
        setTimeout(() => resolve(), 50);
      });
    },
    async excludeFormProductsDelayed(payload: DtoCartItem[]) {
      return new Promise<void>((resolve) => {
        this.excludeFormProducts(payload);
        setTimeout(() => resolve(), 50);
      });
    },
    getProductWithTags(tags: string[]): DtoProduct | undefined {
      const appStateStore = useAppStateStore();

      return appStateStore.settings.products?.find(
        (product: DtoProduct) =>
          product.tags &&
          tags.every((tag) => product.tags?.includes(tag)) &&
          this.cart.purchases.some((purchase) => purchase.sku === product.sku)
      );
    },
    async replaceCardProducts(sku: string, purchaseId?: string | null): Promise<void> {
      await this.cleanCart();
      await this.addProductToCart(sku, purchaseId);
    },
    addForceProductToCart(sku: string, purchaseId?: string | null): void {
      const appStateStore = useAppStateStore();

      appStateStore.addProductToDataLayerBySku(sku);

      const purchases = purchaseId
        ? [
            ...this.cart.purchases,
            {
              purchase_id: purchaseId,
              sku
            }
          ]
        : this.cart.purchases;

      this.setCartPurchases(purchases);
    },
    async addProductToCart(sku: string, purchaseId?: string | null): Promise<void> {
      const appStateStore = useAppStateStore();

      appStateStore.addProductToDataLayerBySku(sku);

      await this.updateFormProductsDelayed([
        {
          purchase_id: purchaseId ?? "",
          sku
        }
      ]);
    },
    async handlePaymentSuccess({ sku, showDiy = false }: { sku: string; showDiy?: boolean }) {
      const appStateStore = useAppStateStore();
      const diyStore = useDiyStore();
      const { modalsState } = useModalsState();

      const isProductDiy = appStateStore.settings.products?.some(
        (product) =>
          product.sku === sku &&
          (product.product_type === CommonProductTypes.ProductTypesBundle ||
            product.product_type === CommonProductTypes.ProductTypesDiyReturn)
      );

      if (isProductDiy) {
        await diyStore.getDiyReturnSettings(true);
        const formsStore = useFormsStore();
        await formsStore.getDiyReturns();
      }

      if (!showDiy) {
        modalsState.openOrderSuccessModal(sku);
        return;
      }

      if (diyStore.isNewAvailable && isProductDiy) {
        await diyStore.redirectToCreateDiy();
        modalsState.closeAllModals();
        diyStore.setState({ lastDiyProductSku: sku });
      } else {
        modalsState.openOrderSuccessModal(sku);
      }
    },
    async makeProfilePaymentFromCart({
      hideSuccessModal = false,
      preventSettingsReload = false,
      showDiy = false
    }: {
      hideSuccessModal?: boolean;
      preventSettingsReload?: boolean;
      showDiy?: boolean;
    }): Promise<{
      success: boolean;
      error?: string;
    }> {
      const paymentsStore = usePaymentsStore();
      const appStateStore = useAppStateStore();
      const formsStore = useFormsStore();
      const authStore = useAuthStore();

      let success: boolean = false;
      let error: string | undefined;

      try {
        if (this.cart?.purchases?.length) {
          if (!paymentsStore.userHasPaymentProfile) {
            Sentry.captureMessage(
              `Profile payment from cart error (no default_payment_profile) userData: ${JSON.stringify(authStore.userData)}`
            );
          }

          const { amount_billed, purchases = [] } = await ChargeProfile(paymentsStore.currentPaymentProfileId ?? "", {
            cart: this.cart,
            source: getEngineSource(),
            device_id: authStore.deviceId,
            action_url: window.location.href
          });

          if (!hideSuccessModal) {
            const sku = this.cart?.purchases[0]?.sku;
            await this.handlePaymentSuccess({ sku, showDiy });
          }

          addPurchaseToDataLayer(purchases ?? [], formsStore.formId ?? "", authStore.funnel, amount_billed);
        } else if (formsStore.canceledFilesmart) {
          await this.reactivateBundle();
        } else if (formsStore.canceledShield) {
          await this.reactivateShield();
        }

        success = true;
      } catch (e: any) {
        Sentry.captureMessage(`Profile payment from cart error: ${e}`);
        error = e.message ?? e;

        success = false;
      } finally {
        await this.cleanCart();

        if (!preventSettingsReload) {
          await appStateStore.getSettings();
          await this.getAllActiveUserPurchases();
          await formsStore.getSubscriptionStatuses();
        }
      }

      return { success, error };
    },
    async makeProfilePayment(params: ProfilePaymentData): Promise<void> {
      const { modalsState } = useModalsState();
      const paymentsStore = usePaymentsStore();
      const eventBus = useEventBus();

      await this.replaceCardProducts(params.sku);

      const { success, error } = await this.makeProfilePaymentFromCart({
        //sku: params.sku, TODO: sku not expected here
        showDiy: params.showDiy
      });
      if (success) {
        await this.handlePaymentSuccess({ sku: params.sku, showDiy: params.showDiy });
        return;
      }
      await paymentsStore.getPaymentProfiles();
      const hasProfiles = paymentsStore.paymentProfiles.length > 0;

      if (!hasProfiles) {
        const openPaymentModalData: OpenPymentModalData = {
          showDiy: params.showDiy
        };

        modalsState.openPaymentModal(openPaymentModalData);

        setTimeout(() => {
          eventBus.$emit("setPaymentError", error);
        }, 1000);
        return;
      }

      this.replaceCardProducts(params.sku);

      //this._filings.closeOfferFilingsModal();
      eventBus.$emit("setShowOfferFilingsModal", { to: false });

      eventBus.$emit("setMerchantToProfile");

      const openPaymentModalData: OpenPymentModalData = {
        showDiy: params.showDiy
      };

      modalsState.openPaymentModal(openPaymentModalData);

      //this._filings.openOfferFilingsModal(true);
      eventBus.$emit("setShowOfferFilingsModal", { to: true });

      eventBus.$emit("setMerchantToProfile");
    },
    async reactivateBundle(): Promise<void> {
      const { modalsState } = useModalsState();
      const eventBus = useEventBus();
      const formsStore = useFormsStore();
      const appStateStore = useAppStateStore();

      try {
        await EditBundleSubscription({
          enable: true,
          action_url: window.location.href,
          source: getEngineSource(),
          subscription_id: formsStore.canceledFilesmart?.subscription_id
        });

        modalsState.openOrderSuccessModal(appStateStore.defaultOrderSKU);
        await Promise.all([this.getAllActiveUserPurchases(), formsStore.getSubscriptionStatuses()]);
      } catch {
        await this.cleanCart();
        eventBus.$emit("setMerchantToProfile");
        modalsState.openPaymentModal({});
      }
    },
    async reactivateShield(): Promise<void> {
      const eventBus = useEventBus();
      const { modalsState } = useModalsState();
      const appStateStore = useAppStateStore();
      const formsStore = useFormsStore();

      try {
        await EditShieldSubscription({
          enable: true,
          action_url: window.location.href,
          source: getEngineSource(),
          subscription_id: formsStore.canceledShield?.subscription_id
        });

        modalsState.openOrderSuccessModal(appStateStore.defaultOrderSKU);

        await Promise.all([this.getAllActiveUserPurchases(), formsStore.getSubscriptionStatuses()]);
      } catch {
        await this.cleanCart();
        eventBus.$emit("setMerchantToProfile");
      }
    },
    moveGuestCartToUser(): void {
      const userData = getCookie<UserData>(cookieNames.userData);
      const userId = userData?.id ?? "guest";

      if (userId !== "guest") {
        const guestCart = getCookie<DtoCart>(`${cookieNames.cartData}-guest`);

        if (guestCart) {
          setCookie(getCartDataCookieName(), guestCart, THREE_DAYS_IN_MS);
          removeCookie(`${cookieNames.cartData}-guest`);

          this.getCartFromCookies();
        }
      }
    }
  }
});
